<template>
	<div class="pui-form">
		<pelppproduct-modals :modelName="modelName"></pelppproduct-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<pelppproduct-form-header :modelPk="modelPk"></pelppproduct-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<v-col cols="4">
							<pui-text-field
								:id="`description-pelppproduct`"
								v-model="model.description"
								:label="$t('pelppproduct.description')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="4">
							<pui-select
								id="producttype"
								attach="producttype"
								:label="$t('pelppproduct.producttype')"
								toplabel
								:itemsToSelect="[{ producttype: model.producttype }]"
								:modelFormMapping="{ producttype: 'producttype' }"
								v-model="model"
								:items="[
									{ producttype: 'HYDROCARBURE', name: 'HYDROCARBURE' },
									{ producttype: 'CRUDE', name: 'CRUDE' }
								]"
								itemValue="producttype"
								itemText="name"
								:order="{ name: 'asc' }"
								reactive
								required
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="4">
							<pui-checkbox
								:id="`heavy`"
								:label="$t('pelppproduct.heavy')"
								:true-value="true"
								v-model="model.heavy"
								:false-value="false"
							></pui-checkbox>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="4">
							<pui-select
								id="family"
								attach="family"
								:label="$t('pelppproduct.family')"
								toplabel
								:itemsToSelect="[{ family: model.family }]"
								:modelFormMapping="{ family: 'family' }"
								v-model="model"
								:items="[
									{ family: 'family1', name: 'family1' },
									{ family: 'family2', name: 'family2' },
									{ family: 'family3', name: 'family3' },
									{ family: 'Opera fuera de la terminal', name: 'Opera fuera de la terminal' }
								]"
								itemValue="family"
								itemText="name"
								:order="{ name: 'asc' }"
								reactive
								required
							></pui-select>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import pelppproductActions from './PelppproductActions';
import pelppproductModals from './PelppproductModals.vue';

export default {
	name: 'pelppproduct-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'pelppproduct-modals': pelppproductModals
	},
	data() {
		return {
			modelName: 'pelppproduct',
			parentModelName: '[PARENT_MODEL_NAME]',
			actions: pelppproductActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
			this.setParentPk();
		},
		setParentPk() {
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
				//this.model.[LOCAL_FK_ATTRIBUTE] = parentModel.pk.[REMOTE_FK_ATTRIBUTE];
			}
		}
	},
	computed: {},
	created() {}
};
</script>
